<template>
  <v-container>
    <v-row justify="center">
      <v-col xl="8" lg="11">
        <v-data-table dense :headers="headers" :items="qrS" item-key="qr" class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">Вы уверены?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue-darken-1" variant="text" @click="closeDelete">Отмена</v-btn>
                    <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="row">
              <v-btn small @click="updateStatus(item)" color="green darken-1" class="mx-1">
                Статус
              </v-btn>
              <v-btn small @click="deleteItem(item)" v-if="item.state === 'Оплачен'" :disabled="isDeleting" color="orange" class="mx-1">
                Возврат
              </v-btn>
            </div>
              
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { format } from "date-fns";
import store from "@/services/store";

export default {
  name: "QrS",
  components: {},
  props: ["id"],
  data() {
    return {
      qrS: [],
      dialogDelete: false,
      headers: [
        {
          text: "Id",
          align: "start",
          sortable: true,
          value: "serviceId",
        },
        { text: "Date", value: "date" },
        { text: "Price", value: "cost" },
        { text: "Status", value: "state" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      isDeleting: false,
      deletedItem: "",
    };
  },
  async mounted() {
    this.getAll();
  },
  watch: {},
  computed: {},
  methods: {
    setStatus(number){
      switch (number) {
          case 0: return "Неизвестно";
          case 1: return "Создан";
          case 2: return "Оплачен";
          case 3: return "Частичная отмена";
          case 4: return "Возвращен";
          case 5: return "Отменен";
          case 6: return "Отклонен";
          case 7: return "Истек срок жизни";
          case 8: return "ожидает подтверждения платежа от СБП";
        }
    },
    setStatusFromString(str){
      switch (str) {
          case 'UNKNOWN': return "Неизвестно";
          case 'CREATED': return "Создан";
          case 'PAID': return "Оплачен";
          case 'REVERSED': return "Частичная отмена";
          case 'REFUNDED': return "Возвращен";
          case 'REVOKED': return "Отменен";
          case 'DECLINED': return "Отклонен";
          case 'EXPIRED': return "Истек срок жизни";
          case 'ON_PAYMENT': return "ожидает подтверждения платежа от СБП";
        }
    },
    async getAll() {
      let resp = await this.$axios.get(this.$enums.Endpoints.Data.QR + `?data=${this.id}`);

      let array = resp.data;
      for (let i = 0; i < array.length; i++) {
        array[i].date = format(new Date(array[i].date), "dd/MM/yyyy HH:mm");
        array[i].cost /= 100;
        array[i].state = this.setStatus(array[i].state)
        
      }
      this.qrS = array;
    },
    async updateStatus(item) {
      try {
        let resp = await this.$axios.get(
          this.$enums.Endpoints.Payment.QrStatus + `?id=${item.serviceId}`
        );
        store.state.snackbar = {
          show: true,
          message: `Текущий статус заказа - ${this.setStatusFromString(resp.data)}`,
          color: resp.data == "PAID" ? "green" : "orange",
          timeout: 2000,
          contentClass: 'text-center text-h5',
        };
        const index = this.qrS.findIndex(qrItem => qrItem.serviceId === item.serviceId);
        if (index !== -1) {
          this.$set(this.qrS, index, { ...item, state: this.setStatusFromString(resp.data) });
        }
      } catch (e) {
        console.log(e)
      }
    },
    async deleteItemConfirm() {
      this.isDeleting = true;
      try {
        let resp = await this.$axios.post(
          this.$enums.Endpoints.Payment.Refund + `?id=${this.deletedItem}`
        );
        store.state.snackbar = {
          show: true,
          message: `Возврат оформлен, текущий статус заказа - ${resp.data}`,
          color: "orange",
          timeout: 2000,
          contentClass: "text-center text-h5",
        };
        this.getAll();
      } catch (e) {
        console.log(e)
        console.log("Refund - Error");
      } finally {
        this.isDeleting = false;
        this.closeDelete()
      }
    },
    deleteItem(item) {
      this.deletedItem = item.serviceId
      this.dialogDelete = true
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.deletedItem = ""
      })
    },
  },
};
</script>

<style></style>
